import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MaxWidth from "../components/Layout/maxWidth"
import Heading from "../components/Layout/heading"
import { bigHeader } from "../components/Layout/contentHeader"
import { styled } from "linaria/react"
import colors from "../styles/colors"

export default function OurCustomers() {
  return (
    <Layout>
      <SEO title="Our Customers" />
      <Heading>
        <h1>Our Customers</h1>
      </Heading>
      <MaxWidth>
        <h2 className={bigHeader}>Impact the world of tomorrow</h2>

        <p>
          Are you often find trapped in anxious, negative, and unproductive
          thinking? Loosing valuable time, focus, and energy because of
          overthinking and worry? Are you still comparing your business with
          other competitors in vain? If you desire to get simple solutions to
          your problems, then you’ve come to the right place. Don’t waste your
          time!
        </p>

        <Flex>
          <div>
            <h3>Number of our customers</h3>
            <LargeText>You</LargeText>
            <p>
              We are working for many clients but it doesn’t help you when we
              aren’t working for you.
            </p>
          </div>
          <div>
            <h3>Number of business ideas</h3>
            <LargeText>31536000</LargeText>
            <p>
              We can choose the best business ideas from millions to create,
              renew or improve your business.
            </p>
          </div>
          <div>
            <h3>Our experience</h3>
            <LargeText>150000</LargeText>
            <p>
              Years ago trading became a principal facility of prehistoric
              people, who bartered goods and services from each other.
            </p>
          </div>
        </Flex>
      </MaxWidth>
    </Layout>
  )
}

const Flex = styled.section`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  margin-top: 4em;
  @media (max-width: 1000px) {
    font-size: 0.85rem;
  }
  > div {
    flex: 0 0 calc(33% - 1em);
    text-align: center;
  }
  @media (max-width: 850px) {
    flex-wrap: wrap;
    > div {
      flex: 0 0 100%;
      margin: 1em 0;
      span {
        margin: 0;
        padding: 0;
        line-height: 1;
      }
    }
  }
`

const LargeText = styled.span`
  font-size: 4em;
  color: ${colors.darkBlue};
  font-weight: 700;
  text-transform: uppercase;
  margin: 0.5em 0;
  display: block;
`
